import React from "react"
import Layout from "../layouts/ru"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import ListMainLayout from '../layouts/listmain'

const IndexPage = (props) => (
  <Layout location={props.location}>
    <SEO title="Home" description="Верхняя страница канала. 241. Вы можете найти свои любимые статьи с различными категориями." lang="ru" />
    <ListMainLayout data={props.data} langKey="ru"/>
  </Layout>
)

export const query = graphql`
  query {
    allMarkdownRemark (filter: {fields: {langKey: {eq: "ru"}}}) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            tags
            author
            price
          }
          fields {
            slug
            langKey
          }
          excerpt
        }
      }
    }
  }
`

export default IndexPage
